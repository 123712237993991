import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  // State
  const [contentHeight, setContentHeight] = useState(window.outerHeight);

  //Display variables

  const display = {
    logoSize: contentHeight * (1.15 / 4),
    copyrightSize: contentHeight * 0.01,
    introText: contentHeight * 0.035,
    introTextSM: contentHeight * 0.025,
  };

  // Hooks
  useEffect(() => {
    console.log(display.logoSize);
  }, [contentHeight, display.logoSize]);

  // Helper functions
  const getContentHeight = () => {
    setContentHeight(window.outerHeight);
  };

  // Window Events
  window.addEventListener("resize", (e) => {
    getContentHeight();
  });
  window.addEventListener("orientationchange", (e) => {
    getContentHeight();
  });

  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{ height: "33vh" }}
        />
        <p>
          <span
            className="affiant-blue"
            style={{ fontSize: display.introText + "px" }}
          >
            Hello Affiant
          </span>
          <br />
          <span
            className="reduced-font"
            style={{ fontSize: display.introTextSM + "px" }}
          >
            coming one day...
          </span>
          <br />
          <span
            className="copy-right"
            style={{ fontSize: display.copyrightSize + "px" }}
          >
            copyright © 2024 affiant.ca
          </span>
          <span
            className="copy-right-tbb"
            style={{ fontSize: display.copyrightSize + "px" }}
          >
            by Thin Blue Bytes Inc.
          </span>
        </p>
      </header>
    </div>
  );
}

export default App;
